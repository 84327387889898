<template>
  <b-modal
    id="early-checkin-modal"
    centered
    size="lg"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    dialog-class="my-dialog-class"
    hide-footer
  >
    <h2 class="w-100 text-center">Early Check-out Policy/ Guidelines</h2>
    <p>
      1. <strong>Early Check-Out</strong> option has been incorporated to cater for exigencies,
      wherein Guests want to/ required to check-out earlier than actual planned
      stay/ booking
    </p>
    <p>
      2. <strong>Early Check-Out Button</strong> is only active (actionable) with Mess Secy/ Mess
      Staff (Reception), hence <strong>Guests are required to approach Mess Reception to
      avail said option</strong>
    </p>
    <!-- <p>
      3. Once Mess Secy/ Mess Staff (Reception) have clicked on <strong>Early Check-Out
      Button</strong>, the same becomes active at Dashboard/ My Bookings of Guest/ User
      who created the said Booking, to ensure that banking details wrt refunds
      are correct
    </p> -->
    <p>
      3. Once Mess Secy/ Mess Staff (Reception) have clicked on <strong>Early Check-Out
      Button</strong>, the fields for necessary banking details
      i.e <strong>Bank Name, Account Title and Account Number (IBAN)</strong> will become available. Please <strong>ENSURE that
      the data provided to Mess Staff/ Mess Receptionist is accurate/ correct, </strong>otherwise the software-based
      refunds will either be not executed (transfer failed) or else will be
      remitted to the wrong person i.e. the wrong IBAN Bank Account number
      provided.
    </p>
    <!-- <p>
      4. Hence forth, the guest is prompted/ required to provide banking details
      i.e <strong>Bank Name, Account Title and Account Number (IBAN)</strong>. Please <strong>ENSURE that
      the data provided is accurate/ correct</strong>otherwise the software-based
      refunds will either be not executed (transfer failed) or else will be
      remitted to the wrong person i.e. the wrong IBAN Bank Account number
      provided
    </p> -->
    <p>
      4. The <strong>guests can’t avail said option after 1600 hours on a day prior to
      Check-Out Date</strong>, hence the <strong>Early Check-Out Button</strong> becomes inactive again at
      1600 hours on a day prior to Check-Out Date
    </p>
    <p>
      5. The <strong>“Early Check-Out Button”</strong> is visible from outset, however only
      becomes actionable: -
    </p>
    <div class="ml-2">
      <p>a. If the actual booking is of more than one day</p>
      <p>b. If the Mess Staff has pressed/ executed Check-In Button</p>
      <p>
        c. If there are still clear 24 hours left from 1600 hours of Check-Out
        Date
      </p>
      <p>
        d. At user/ guest Dashboard (My Bookings), once/ after the Mess Secy or
        Mess Staff (Reception) have pressed/ Clicked <strong>Early Check-Out Button</strong> at
        their respective Dashboards against that specific <strong>Booking/ Voucher ID</strong>
      </p>
    </div>
    <p>
      6. The Guest’s Refund is calculated after subtracting consumed days and on
      the basis of ONE day of guest rooms charges only. The <strong>time based
      deductions are</strong>:-
    </p>
    <div class="ml-2">
      <p>
        a. <strong>5%:</strong> If more than 72 hours are left until 1200 hours on Check-Out Date
      </p>
      <p>
        b. <strong>25%:</strong> If less than 72 hours and more than 48 hours are left until 1200
        hours on Check-Out Date
      </p>
      <p>
        c. <strong>50%:</strong> If less than 48 hours but more than 24 hours are left until 1200
        hours on Check-Out Date
      </p>
      <p>
        d. <strong>75%:</strong> If cancelled between 1200 hours to 1600 hours on a day prior to
        Check-out Date
      </p>
      <p>
        e. <strong>100% (No Refund):</strong> After 1600 hours on a day prior to Check-Out Date
      </p>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VueSelectPaginated from "@/components/ui/VueSelectPaginated.vue";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import util from "@/util.js";
import moment from "moment";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueSelectPaginated,
  },
  mixins: [util, togglePasswordVisibility],

  data() {
    return {};
  },
  async mounted() {},
};
</script>
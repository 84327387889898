<template>
  <footer class="custom-footer">
    <div class="footer-background">
      <b-container class="footer-container">
        <b-row class="">

          <b-col lg="4" md="12" class="mb-md-3">
            <div class="footer-logo-and-description">
              <img src="~@/assets/images/logo/bookinglogo.png" alt="Company Logo" class="footer-logo">
              <h3 style="color: rgb(187, 187, 0);">Guest Room Booking</h3>
              <p>Welcome to Guest Room Booking. Your go-to destination for seamless guest room bookings. Explore our diverse accommodations, book online with ease, and enjoy a comfortable stay. Contact us for assistance. Book now!</p>
            </div>
          </b-col>
          

          <b-col lg="2" md="6" sm="12" class="mb-md-3 px-4">
            <div class=" mt-5">
              <h6 style="color: rgb(187, 187, 0);">Contact</h6>
              <p class="text-truncate"> <feather-icon icon="PhoneIcon" size="16" class="mr-1" />051
            111-130-786</p>
            </div>
          </b-col>

          <b-col lg="2" md="6" sm="12" class="mb-md-3 ">
            <div class="border-left h-50 pl-3 mt-5">
              <h6 style="color: rgb(187, 187, 0);">Home</h6>
              <a class="text-white" href="#">Payment Guide</a>
            </div>
          </b-col>

          <b-col lg="2" md="6" sm="12" class="mb-md-3">
            <div class="border-left h-50 pl-3 mt-5">
              <h6 style="color: rgb(187, 187, 0);">Room Type</h6>
              <p>VIP Room</p>
              <p>Standards</p>
            </div>
          </b-col>

  
          <b-col lg="2" md="6" sm="12" class="mb-md-3">
            <div class="border-left h-50 pl-3 mt-5">
              <h6 style="color: rgb(187, 187, 0);">Locations</h6>
              <p>Islamabad</p>
              <p>Rawalpindi</p>
              <p>Lahore</p>
              <p>Murree</p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </footer>
</template>


<script>
export default {

};
</script>

<style scoped>
.custom-footer {
  padding: 10px 0;
  background-image: url("~@/assets/booking/footer.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center; 
  max-width: 1500px; 
  margin: 0 auto;
  padding: 0 20px;
  color: white !important;
}

.footer-inner-container {
  align-content: stretch;
}

.footer-logo {
  width: 150px; 
  margin-bottom: 20px;
}

.border-left{
  border-left: 5px solid white; 
  padding-left: 20px;
}
p{
   color: white !important;
}
@media (max-width: 990px) and (max-height: 844px) {
  .border-left {
    border-left: none !important; 
  }
}

</style>

<template>
  <div class="bg-white">
    <template>
      <div>
       <HomeFilter @search="search" />
      </div>
    </template>
     <div class="section-heading d-flex justify-content-center mt-5">
      <h2 class="bg-primary text-white p-50" style="border-radius: 5px;">My Bookings</h2>
    </div>
    <div class="bookingHistory">
      <BookingHistory/>
    </div>
    <BroadPolicyModal
      @modalClosed="onModalClosed"
      :showModal="visible"
      :key="`view-${broadPolicyModalCount}`"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import util from "@/util.js";
import "swiper/css/swiper.css";
import HomeFilter from "@/components/booking/home/HomeFilter.vue";
import BookingHistory from "@/components/booking/history/BookingHistory.vue";
import BroadPolicyModal from "@/components/booking/admin/BroadPolicyModal.vue";

export default {
  components: {
    VueApexCharts,
    VuePerfectScrollbar,
    flatPickr,
    Swiper,
    SwiperSlide,
    HomeFilter,
    BookingHistory,
    BroadPolicyModal
  },
  mixins: [util],
  data() {
    return {
      broadPolicyModalCount: 0,
      visible: false,
      selectedRooms: "1",
      searchResults: [],
      destinations: [
        {
          id: 1,
          name: "Murree",
          image: require("@/assets/booking/chenar.jpeg"),
        },
        {
          id: 2,
          name: "Karachi",
          image: require("@/assets/booking/karachi.jpeg"),
        },
        {
          id: 3,
          name: "Peshawar",
          image: require("@/assets/booking/peshawar.jpeg"),
        },
        {
          id: 4,
          name: "Lahore",
          image: require("@/assets/booking/lahore.jpeg"),
        },
      ],
      cities: [
        { id: 1, name: "Lahore" },
        { id: 2, name: "Islamabad" },
        { id: 3, name: "Karachi" },
        { id: 4, name: "Sahiwal" },
        { id: 5, name: "Faisalabad" },
        { id: 6, name: "Peshawar" },
        { id: 7, name: "Rawalpindi" },
        { id: 8, name: "Gujrawala" },
        { id: 9, name: "Quetta" },
        { id: 10, name: "Sakkhar" },
      ],
      selectedCity: null,

      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        wheelPropagation: false,
        useBothWheelAxes: false,
        suppressScrollX: true,
      },
      swiperOptions: {
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: 30,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  created() {},
  async mounted() {
    this.openBroadPolicyModal()
    this.selectedCity = this.cities[0];
    let abc = await this.getRoomTypes();
    
  },

  methods: {
    ...mapActions({
      getAvailableRooms: "appData/getAvailableRooms",
      getRoomTypes: "appData/getRoomTypesUnpaginated",
    }),

    async RoomInfo() {
      
    },
    async search(filters) {
      try {
        this.$router.push({ name: "GuestHouses" });
      } catch (error) {
        this.displayError(error);
      }
    },

    async fetchRoomsByCity(city) {
      try {
    
        let res = await this.getAvailableRooms({ id});
        if (res.status == 200) {
          this.$router.push({ name: "GuestHouses" });
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    openBroadPolicyModal() {
      this.broadPolicyModalCount += 1;
      this.visible = true;
      this.$nextTick(() => {
        this.$bvModal.show("broad-policy-modal");
      });
    },
    onModalClosed() {
      this.visible = false;
      this.$bvModal.hide("broad-policy-modal");
    },
  },

  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
      getLoggedInUser: "appData/getUser",
    }),
  },
  watch: {
    // toSelectedSession(newValue, oldValue) {
    //   if (newValue) {
    //     this.dashboardData();
    //   }
    // }
  },
};
</script>

<!-- <style lang="scss">
    @import "@core/scss/vue/libs/vue-flatpicker.scss";
    @import "@core/scss/vue/libs/swiper.scss";
    </style> -->

<style scoped>
.hotel-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.card {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 10px;
  text-align: center;
}

.card img {
  width: 100%;
  height: auto;
}

.card:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.destination-card {
  width: 250px;
  margin: 0;
}

</style>

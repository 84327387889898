<template>
  <div class="background-image">
    <validation-observer ref="searchValidation">
      <b-row>
        <b-col md="8" class="text-center text-md-left">
          <div class="ml-md-5 ml-0" style="padding-top: 150px">
            <div class="d-flex ml-md-5 ml-0 py-2 mb-0 flex-column filter-class">
              <h3 style="font-size: 24px;" >
                <span class="bg-primary text-white rounded" style=" padding: 4px 15px;"
                  >Explore & Reserve</span
                >
              </h3>
              <h1
                class="text-bold text-white"
                style="font-size: 36px; font-weight: bold; line-height: 1; text-shadow: 1px 1px #3b3c36;"
              >
                WELCOME TO<br />GUEST ROOM BOOKING
              </h1>
              <h2 class="text-white" style="font-size: 24px; text-shadow: 1px 1px #3b3c36;">
                FIND YOUR PERFECT STAY
              </h2>
            </div>
          </div>
        </b-col>

        <b-col md="4" class="text-center">
          <div
            class="filters-background d-flex flex-column justify-content-start align-items-center my-padding"
          >
            <h2 class="font-weight-bolder" style="color: #3b3c36 !important;">REFINE YOUR STAY</h2>
            <b-col cols="10" class="customize">
              <p class="text-black ml-0 pl-0">
                Personalize your booking preferences below to discover the perfect guest 
                room for your stay. Choose your desired city and set check-in and
                check-out dates for a tailored booking experience.
              </p>
            </b-col>
            <b-col cols="10">
              <b-form-group label-for="city">
                <template #label>
                  <div style="text-align: left !important; font-size: 1.2em !important;"><strong>City:</strong></div>
                </template>
                <v-select
                  inputId="id"
                  label="name"
                  v-model="selectedCity"
                  :options="cities"
                  autoscroll
                  :clearable="false"
                  class="bg-white"
                  style="border-radius: 0.357rem"
                  :disabled="allDisabled"
                ></v-select>
              </b-form-group>
            </b-col>

            <b-col cols="10">
              <b-form-group label-for="checkinDate">
                <template #label>
                  <div style="text-align: left !important; font-size: 1.2em !important;"><strong>Check-in Date:</strong></div>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="checkinDatefield"
                  :rules="{ required }"
                >
                  <b-input-group>
                    <b-form-input
                      id="checkinDateInput"
                      v-model="checkinDate"
                      type="text"
                      placeholder="YYYY-MM-DD"
                      autocomplete="on"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        id="checkinDate"
                        v-model="checkinDate"
                        button-variant="primary"
                        :min="minCheckinDate"
                        :max="maxCheckinDate"
                        button-only
                        right
                        variant="primary"
                        style="height: 38px"
                        :disabled="allDisabled"
                        :date-disabled-fn="checkinDatesDisabled"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-yellow">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="10">
              <b-form-group label-for="checkoutDate">
                <template #label>
                  <div style="text-align: left !important; font-size: 1.2em !important;"><strong>Check-out Date:</strong></div>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="checkoutDatefield"
                  :rules="{ required }"
                >
                  <b-input-group>
                    <b-form-input
                      id="checkoutDateInput"
                      v-model="checkoutDate"
                      type="text"
                      placeholder="YYYY-MM-DD"
                      autocomplete="on"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        id="checkoutDate"
                        v-model="checkoutDate"
                        button-variant="primary"
                        :min="minCheckoutDate"
                        :max="maxCheckoutDate"
                        button-only
                        right
                        variant="primary"
                        style="height: 38px"
                        :disabled="allDisabled"
                        :date-disabled-fn="checkoutDatesDisabled"
                      />
                    </b-input-group-append>
                  </b-input-group>

                  <small
                    class="text-yellow bg-dark"
                    style="position: absolute"
                    >{{ errors[0] }}</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- <b-col cols="10">
              <b-form-group label-for="bookingFor">
                <v-select
                  inputId="id"
                  label="name"
                  v-model="bookingFor"
                  :options="bookingForOptions"
                  autoscroll
                  :clearable="false"
                  class="bg-white"
                  style="border-radius: 0.357rem"
                  :disabled="allDisabled"
                ></v-select>
              </b-form-group>
            </b-col> -->

            <b-col cols="10">
              <b-form-group>
                <template #label>
                  <div style="text-align: left !important; font-size: 1.1em !important;"><strong>Booking For:</strong></div>
                </template>
                <b-form-radio-group
                  v-model="selectedBookingFor"
                  :options="bookingForOptions"
                  value-field="id"
                  text-field="name"
                  name="bookingFor"
                  style="text-align: left !important; font-weight: bold !important;"
                ></b-form-radio-group>
              </b-form-group>
            </b-col>

            <b-col cols="10">
              <b-button variant="primary" @click="openFiltersModal" class="w-100" :disabled="allDisabled">
                <feather-icon icon="SearchIcon" class="mr-50" />
                <span class="align-middle">Search</span>
              </b-button>
            </b-col>
          </div>
        </b-col>
      </b-row>
    </validation-observer>
    <b-modal id="filter-modal" v-model="showFiltersModal" hide-footer no-close-on-backdrop no-close-on-esc centered size="lg" @hidden="cancelFiltersModal">
      <template v-if="!allDisabled">
        <div class="text-center"><feather-icon icon="AlertTriangleIcon" size="60" class="mr-1 mt-1"/></div>
        <h1 class="text-center">Please confirm the following search filters by checking all the boxes</h1>
      <div class="modal-body">
        <b-form-checkbox v-model="checkinDateChecked" name="checkin-date" :state="checkinDateChecked">
          <h3 class="mb-1"><strong>Check In Date: <div class="text-primary">{{ checkinDate }}</div></strong> </h3>
        </b-form-checkbox>
        <b-form-checkbox v-model="checkoutDateChecked" name="checkout-date" :state="checkoutDateChecked">
          <h3 class="mb-1"><strong>Check Out Date:  <div class="text-primary">{{ checkoutDate }}</div></strong></h3>
        </b-form-checkbox>
        <b-form-checkbox v-model="bookingForChecked" name="booking-for" :state="bookingForChecked">
          <h3 class="mb-1"><strong>Booking For: <div class="text-primary">{{ bookingFor ? bookingFor.name : ''}}</div></strong></h3>
        </b-form-checkbox>
        <b-form-checkbox v-model="selectedCityChecked" name="selected-city" :state="selectedCityChecked">
        <h3><strong>City: <div class="text-primary">{{ selectedCity ? selectedCity.name : ''}}</div></strong></h3>
        </b-form-checkbox>
      </div>
      <div class="text-center">
        <h3 class="text-danger">
          Please note that dates are in the following format <br/> YYYY-MM-DD (Year-Month-Day)
        </h3>
      </div>
      </template>
      <div class="modal-footer">
        <b-button variant="outline-secondary" @click="cancelFiltersModal">Cancel</b-button>
        <b-button variant="primary" @click="confirmFilterModal" :disabled="!this.checkinDateChecked || !this.checkoutDateChecked || !this.bookingForChecked || !this.selectedCityChecked">Confirm</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { $themeColors } from "@themeConfig";
import flatPickr from "vue-flatpickr-component";
import util from "@/util.js";
import { required } from "@validations";

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [util],
  props: {
    prePopulateData: Object,
  },
  data() {
    return {
      required,
      dateRange: [],
      guestHouseName: null,
      cities: [],
      selectedCity: null,
      // globalFilters: null,
      checkinDate: "",
      checkinDateObj: null,
      checkoutDate: "",
      checkoutDateObj: null,
      showComponent: false,
      bookingFor: null,
      selectedBookingFor: null,
      selectedOption: null,
      bookingForOptions: [
        {
          id: 1,
          name: "Self (Self, Wife, Parents and Unmarried children under 24 years of age)",
        },
        {
          id: 2,
          name: "Civil guests",
        },
      ],
      filterSuccess: false,
      maxCheckinDate: "",
      maxCheckinDateObj: null,
      minCheckoutDate: "",
      minCheckoutDateObj: null,
      maxCheckoutDate: "",
      maxCheckoutDateObj: null,
      minCheckinDate: "",
      minCheckinDateObj: null,
      allDisabled: false,
      showFiltersModal: false,
      checkinDateChecked: false,
      checkoutDateChecked: false,
      bookingForChecked: false,
      selectedCityChecked: false,
      status: false,
      isMounted: false,
      //BOOKED DATES DISABLED
      datesBooked: [],
      checkinDatesDisabledArr: [],
      checkoutDatesDisabledArr: [],
      updateCheckout: false,
      //BOOKED DATES DISABLED
    };
  },
  created() {},
  async mounted() {
    //BOOKED DATES DISABLED
    if(this.getLoggedInUser.role_data.code_name !== 'su'){
      await this.fetchLoggedInUser()
    }
    //BOOKED DATES DISABLED
    this.allDisabled = true
    await this.fetchGlobalPoliciesState()

    if(!this.getGlobalPoliciesState)
    {
      this.setCheckinDate(await this.getCurrentDate())
      this.setCheckoutDate(await this.getCurrentDate())
      this.allDisabled = true
      return 
    }

    if (this.getLoggedInUser.user_type !== this.userTypes.SERVING) {
      this.bookingForOptions = this.bookingForOptions.slice(0, 1);
      this.bookingForOptions[0].name = "Self Only (Self, Wife and Children under 24 years of age)"
    }
    // this.bookingFor = this.bookingForOptions[0];
    await this.setSelectedBookingFor(1);

    await this.setCities();

    //BOOKED DATES DISABLED
    this.datesBooked =  this.getLoggedInUser.unavailable_dates
    this.checkinDatesDisabledArr = this.datesBooked.slice()
    //BOOKED DATES DISABLED


    // this.minCheckinDateObj = await this.getCurrentDate()
    // this.minCheckinDate = await this.getPakistanDateString(this.minCheckinDateObj);
    // await this.updateMaxCheckinDate();
    if (this.prePopulateData && Object.keys(this.prePopulateData).length >= 0) {
      if ("date_from" in this.prePopulateData) {
        // var dateFrom = new Date(this.prePopulateData.date_from + ' 00:00:00 +05:00');

        let dateStringISO8601 = this.prePopulateData.date_from + ' 00:00:00 +05:00'
        let parts = dateStringISO8601.split(' ');
        let datePart = parts[0]; // '2024-07-05'
        let timePart = parts[1]; // '00:00:00'
        let offsetPart = parts[2]; // '+05:00'
        let formattedDateString = datePart + 'T' + timePart + offsetPart;
        var dateFrom = new Date(formattedDateString);

        // await this.setCheckinDate(dateFrom);

        let checkinDateObj = new Date(dateFrom.getTime())
        let checkinDate = await this.getPakistanDateString(checkinDateObj);
        
        if(this.checkinDatesDisabledArr.includes(checkinDate)){
          this.updateCheckout = true
          while(this.checkinDatesDisabledArr.includes(checkinDate)){
            const nextDay = new Date(checkinDateObj.getTime())
            nextDay.setDate(nextDay.getDate() + 1);
            checkinDateObj = new Date(nextDay.getTime())
            checkinDate = await this.getPakistanDateString(checkinDateObj);
          }
        }

        await this.setCheckinDate(checkinDateObj);
      }
      if ("date_to" in this.prePopulateData) {
        // var dateTo = new Date(this.prePopulateData.date_to + ' 00:00:00 +05:00');

        let dateStringISO8601 = this.prePopulateData.date_to + ' 00:00:00 +05:00'
        let parts = dateStringISO8601.split(' ');
        let datePart = parts[0]; // '2024-07-05'
        let timePart = parts[1]; // '00:00:00'
        let offsetPart = parts[2]; // '+05:00'
        let formattedDateString = datePart + 'T' + timePart + offsetPart;
        var dateTo = new Date(formattedDateString);

        await this.setCheckoutDate(dateTo);
      }
      if ("city" in this.prePopulateData) {
        for (let i = 0; i < this.cities.length; i++) {
          if (this.cities[i].id == this.prePopulateData.city) {
            // this.selectedCity = this.cities[i];
            await this.setCity(this.cities[i])
            break
          }
        }
      }
      if ("booking_for" in this.prePopulateData) {
        // this.bookingFor = this.prePopulateData.booking_for;
        this.selectedBookingFor = this.prePopulateData.booking_for.id;
      }
    } else {
      // this.selectedCity = this.cities[0];
      await this.setCity(this.cities[0])
      // await this.setCheckinDate(this.minCheckinDateObj);
    }
    this.allDisabled = false
    this.isMounted = true
  },

  methods: {
    ...mapActions({
      getBookingCitiesUnpaginated: "appData/getBookingCitiesUnpaginated",
      fetchGlobalPoliciesState: "appData/fetchGlobalPoliciesState",
      fetchSystemTime: "appData/fetchSystemTime",
      fetchLoggedInUser: "appData/fetchLoggedInUser",
    }),
    checkinDatesDisabled(dateString, date){
      //BOOKED DATES DISABLED
      if(this.checkinDatesDisabledArr.includes(dateString)){
        return true
      }
      //BOOKED DATES DISABLED
      return false
    },
    checkoutDatesDisabled(dateString, date){
      //BOOKED DATES DISABLED
      if(this.checkoutDatesDisabledArr.includes(dateString)){
        return true
      }
      //BOOKED DATES DISABLED
      return false
    },
    openFiltersModal(){
      this.showFiltersModal = true
    },
    confirmFilterModal(){
      if (
        !this.checkinDateChecked ||
        !this.checkoutDateChecked ||
        !this.bookingForChecked ||
        !this.selectedCityChecked
      ) {
        this.$swal({
            text: "Please confirm all search filters to proceed",
            icon: "error",
          });
      }
      else
      {
        this.emitFilters()
        this.checkinDateChecked = false
        this.checkoutDateChecked = false
        this.bookingForChecked = false
        this.selectedCityChecked = false
        this.showFiltersModal = false
      }
    },
    cancelFiltersModal(){
      this.checkinDateChecked = false
      this.checkoutDateChecked = false
      this.bookingForChecked = false
      this.selectedCityChecked = false
      this.showFiltersModal = false
    },
    async setCheckinDate(tempDate) {
      var checkinDateObj = new Date(tempDate.getTime())
      var checkinDate = await this.getPakistanDateString(tempDate);
      this.checkinDateObj = checkinDateObj
      this.checkinDate = checkinDate;
    },
    async setCheckoutDate(tempDate) {
      var checkoutDateObj = new Date(tempDate.getTime())
      var checkoutDate = await this.getPakistanDateString(tempDate);
      this.checkoutDateObj = checkoutDateObj
      this.checkoutDate = checkoutDate;
    },
    async setCity(city){
      this.selectedCity = city
    },
    async setCities() {
      let res = null;
      try {
        res = await this.getBookingCitiesUnpaginated({
          mess_only: true,
        });
        if (res.status === 200) {
          this.cities = res.data;
          // this.selectedCity = this.cities[0];
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    async emitFilters() {
      const localFilters = {
        date_from: this.checkinDate,
        date_to: this.checkoutDate,
        // city: this.selectedCity ? this.selectedCity.name : null,
        city: this.selectedCity ? this.selectedCity.id : null,
        booking_for: this.bookingFor ? this.bookingFor : null,
      };
      localStorage.setItem("globalFilters", JSON.stringify(localFilters));
      this.$emit("search", this.localFilters || {});
    },

    async updateMaxCheckinDate() {
      var maxCheckinDateObj = new Date(this.minCheckinDateObj.getTime())
      var maxCheckinDate = await this.getPakistanDateString(maxCheckinDateObj);
      let bookingValidity = parseInt(this.getGlobalPoliciesState.max_days_adv_booking);
      if(!bookingValidity || bookingValidity == 0)
      {
        this.allDisabled = true
        this.maxCheckinDate = maxCheckinDate;
        return
      }
      maxCheckinDateObj.setDate(this.minCheckinDateObj.getDate() + bookingValidity);
      maxCheckinDate = await this.getPakistanDateString(maxCheckinDateObj);
      this.maxCheckinDateObj = maxCheckinDateObj
      this.maxCheckinDate = maxCheckinDate;
    },
    async updateCheckoutDate() {
        const nextDay = new Date(this.checkinDateObj.getTime())
        nextDay.setDate(nextDay.getDate() + 1);

        var minCheckoutDateObj = new Date(nextDay.getTime());
        var minCheckoutDate = await this.getPakistanDateString(minCheckoutDateObj);

        var maxCheckoutDateObj = new Date(nextDay.getTime());
        var maxCheckoutDate = await this.getPakistanDateString(maxCheckoutDateObj);

        let maxBookingDays = parseInt(this.getGlobalPoliciesState.max_booking_days);
        if(!maxBookingDays || maxBookingDays == 0)
        {
          this.allDisabled = true
          this.minCheckoutDate = minCheckoutDate
          this.maxCheckoutDate = maxCheckoutDate
          return
        }
        // maxCheckoutDateObj.setDate(this.checkinDateObj.getDate() + maxBookingDays);
        maxCheckoutDateObj.setDate(nextDay.getDate() + (maxBookingDays - 1));
        maxCheckoutDate = await this.getPakistanDateString(maxCheckoutDateObj);

        this.minCheckoutDateObj = minCheckoutDateObj
        this.minCheckoutDate = minCheckoutDate;
        this.maxCheckoutDateObj = maxCheckoutDateObj
        this.maxCheckoutDate = maxCheckoutDate;

        //DISABLED DATES
        let dates = [];
        let startDate = new Date(this.minCheckoutDateObj.getTime());
        let endDate = new Date(this.maxCheckoutDateObj.getTime());
        let indexDateObj = new Date(startDate.getTime());

        let disableDates = false
        while (indexDateObj <= endDate) {
          dates.push(indexDateObj);
          let indexDate = await this.getPakistanDateString(indexDateObj);
          if(disableDates){
            this.checkoutDatesDisabledArr.push(indexDate)
          }
          if(this.datesBooked.includes(indexDate)){
            disableDates = true
          }
          indexDateObj.setDate(indexDateObj.getDate() + 1);
        }
        //DISABLED DATES
    },
    async getCurrentDate() {
      await this.fetchSystemTime()
      var systemDateTime  = await this.getSystemTime;
      var currentDate = new Date(systemDateTime);
      return currentDate
    },
    async getPakistanDateString(date){
      date = date.toLocaleString("en-US", {timeZone: "Asia/Karachi"}).split(",")[0];
      var day = date.split("/")[1]
      var month = date.split("/")[0]
      var year = date.split("/")[2]
      return year + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0');
    },
    async setSelectedBookingFor(value){
      this.selectedBookingFor = value
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
      getLoggedInUser: "appData/getUser",
      getGlobalPoliciesState: "appData/getGlobalPoliciesState",
      getSystemTime: "appData/getSystemTime",
    }),
    shouldShowComponent() {
      return this.$route.name !== "BookingHome";
    },
  },

  watch: {
    async selectedBookingFor(newValue, oldValue) {
      if(newValue){
        let filtered = this.bookingForOptions.filter(item => item.id == newValue);
        if(filtered){
          this.bookingFor = filtered[0]
        }
      }
    },
    async checkinDate(newValue, oldValue) {
      if(newValue){
        // this.checkinDateObj = new Date(newValue + ' 00:00:00 +05:00');

        let dateStringISO8601 = newValue + ' 00:00:00 +05:00'
        let parts = dateStringISO8601.split(' ');
        let datePart = parts[0]; // '2024-07-05'
        let timePart = parts[1]; // '00:00:00'
        let offsetPart = parts[2]; // '+05:00'
        let formattedDateString = datePart + 'T' + timePart + offsetPart;
        this.checkinDateObj = new Date(formattedDateString);

        await this.updateCheckoutDate();
        if ( !(this.prePopulateData && Object.keys(this.prePopulateData).length >= 0) || oldValue || this.updateCheckout ) {
          // this.checkoutDate = this.minCheckoutDate;
          await this.setCheckoutDate(this.minCheckoutDateObj)
        }
      }
    },
    async selectedCity(newValue, oldValue){
      if(newValue)
      {
        this.allDisabled = true
        // let cityMinimumDateObj = new Date(newValue.minimum_booking_date + ' +05:00');

        let dateStringISO8601 = newValue.minimum_booking_date + ' +05:00'
        let parts = dateStringISO8601.split(' ');
        let datePart = parts[0]; // '2024-07-05'
        let timePart = parts[1]; // '00:00:00'
        let offsetPart = parts[2]; // '+05:00'
        let formattedDateString = datePart + 'T' + timePart + offsetPart;
        let cityMinimumDateObj = new Date(formattedDateString);

        let cityMinimumDate = await this.getPakistanDateString(cityMinimumDateObj);
        this.minCheckinDateObj = await this.getCurrentDate()
        this.minCheckinDate = await this.getPakistanDateString(this.minCheckinDateObj);            
        if(cityMinimumDateObj > this.minCheckinDateObj){
          this.minCheckinDateObj = new Date(cityMinimumDateObj.getTime())
          this.minCheckinDate = cityMinimumDate
        }

        let checkinDateObj = new Date(this.minCheckinDateObj.getTime())
        let checkinDate = this.minCheckinDate

        while(this.checkinDatesDisabledArr.includes(checkinDate)){
          const nextDay = new Date(checkinDateObj.getTime())
          nextDay.setDate(nextDay.getDate() + 1);
          checkinDateObj = new Date(nextDay.getTime())
          checkinDate = await this.getPakistanDateString(checkinDateObj);
        }

        // if(newValue.name.toLowerCase().includes('murree addl'))
        // {
        //   this.minCheckinDate = "2024-07-08"
        //   this.minCheckinDateObj = new Date(this.minCheckinDate + ' 00:00:00 +05:00');
        // }
        // else
        // {
        //   this.minCheckinDateObj = await this.getCurrentDate()
        //   this.minCheckinDate = await this.getPakistanDateString(this.minCheckinDateObj);
        // }

        await this.updateMaxCheckinDate();
        if( !(this.prePopulateData && Object.keys(this.prePopulateData).length >= 0) || oldValue){
          // await this.setCheckinDate(this.minCheckinDateObj);
          await this.setCheckinDate(checkinDateObj);
        }
        this.allDisabled = false
      }
    }
  },
};
</script>

<style scoped>
.text-yellow {
  color: rgb(0, 255, 34);
}
.background-image {
  background: url("~@/assets/booking/header.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.background-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}
.my-padding {
  padding-top: 25rem;
  padding-bottom: 10rem;
}
.filters-background {
  padding-top: 5rem;
  background: url("~@/assets/booking/bookingoverlay.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}


</style>

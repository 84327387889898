<template>
  <div>
    <!-- <div  class=" bg-primary d-flex justify-content-center align-items-center h-100 p-2">
        <div class="d-flex align-items-center ml-2">
            <b-img :src="this.appLogoImage" alt="logo" width="40" />
        </div>
        <template v-if="isLoggedIn"> 

            <b-link
              class="d-flex align-items-center ml-5 text-white"
              :to="{ name: 'User' }"
              v-if="hasPermission('user_show')"
            >
              <feather-icon icon="UserIcon" size="16" class="mr-50" />
              <span>Users</span>
            </b-link>
      
            <b-link
              class="d-flex align-items-center ml-2 text-white"
              :to="{ name: 'Role' }"
              v-if="hasPermission('role_show')"
            >
              <feather-icon icon="UsersIcon" size="16" class="mr-50" />
              <span>Roles</span>
            </b-link>


            <div class="
                    d-flex
                    justify-content-center
                    align-items-center
                    h-100
                    px-2
                    ml-auto
                ">
                <b-navbar-nav class="nav align-items-center">
                <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
                    <template #button-content>
                    <div class="d-sm-flex d-none user-nav">
                        <p class="user-name font-weight-bolder mb-0 text-white">
                        {{ getLoggedInUser ? getLoggedInUser.name : "" }}
                        </p>
                    </div>
                    <b-avatar width="40" badge :src="getLoggedInUser ? getLoggedInUser.profile_image : ''"
                        class="badge-minimal ml-50" badge-variant="success" />
                    </template>
        
                    <b-dropdown-item-button
                    :to="{ name: 'Profile' }"
                    button-class="w-100 text-white"
                    >
                    <feather-icon size="16" icon="UserIcon" class="mr-50" />
                    <span class="text-white">Profile</span>
                    </b-dropdown-item-button>
        
                    <b-dropdown-divider />
        
                    <b-dropdown-item-button @click="logoutButtonClick" button-class="w-100 text-white">
                    <feather-icon size="16" icon="LogOutIcon" class="mr-50 " />
                    <span class="text-white">Logout</span>
                    </b-dropdown-item-button>
                </b-nav-item-dropdown>
                </b-navbar-nav>
            </div>
        </template>
        <template v-else>
            <div class="
                    d-flex
                    justify-content-center
                    align-items-center
                    h-100
                    px-2
                    ml-auto
                ">
                <div class="d-flex justify-content-center align-items-center flex-nowrap mr-2">
                    <b-link
                        class="d-flex align-items-center text-white"
                        :to="{ name: 'Login' }"
                        v-if="!isLoggedIn"
                        >
                        <feather-icon icon="UserIcon" size="16" class="mr-50" />
                        <span>Login</span>
                    </b-link>
                </div>
                <div class="d-flex justify-content-center align-items-center flex-nowrap mr-5">
                    <b-link
                        class="d-flex align-items-center text-white"
                        :to="{ name: 'Register' }"
                        v-if="!isLoggedIn"
                        >
                        <feather-icon icon="UsersIcon" size="16" class="mr-50" />
                        <span>Register</span>
                    </b-link>
                </div>
            </div>
        </template>
      </div> -->

    <b-navbar
      toggleable="sm"
      type="dark"
      variant="primary"
      class="py-50 px-2"
      ref="mainNavbar"
    >
      <b-navbar-brand
        href="/home"
        class="d-flex justify-content-center align-items-center"
      >
        <!-- <b-img :src="this.appLogoImage" alt="logo" />
          <h4 class="m-0 ml-1 brand-text text-white font-weight-bolder">
            {{ this.appFullName }}
          </h4> -->
        <template>
          <div
            class="bg-primary d-flex justify-content-center flex-nowrap align-items-center h-100"
          >
            <b-img
              :src="this.appLogoImage"
              alt="logo"
              width="40"
              @click="abc"
            />

            <h4
              class="m-0 ml-1 brand-text text-white font-weight-bolder d-none d-sm-block"
            >
              {{ this.appName }}
              <!-- Abc -->
            </h4>
            <h6
              class="m-0 ml-1 brand-text text-white font-weight-bolder d-sm-none"
            >
              {{ this.appName }}
              <!-- Abc -->
            </h6>
          </div>
        </template>
        <!-- <template v-else>
            <b-img :src="this.appLogoImage" alt="logo" />
            <h4 class="m-0 ml-1 brand-text text-white font-weight-bolder">
              {{ this.appFullName }}
            </h4>         
          </template> -->
      </b-navbar-brand>

      <b-navbar-toggle
        target="nav-collapse"
        class="bg-primary"
        v-model="isToggled"
      ></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav class="text-right" v-model="isOpen">
        <b-navbar-nav class="ml-1">
            <b-nav-item class="d-flex align-items-center">
              <b-link class="text-white" :to="{ name: 'BookingHome' }">
                <feather-icon icon="HomeIcon" size="15" class="mr-50" />
                <span class="align-middle">Home</span>
              </b-link>
           </b-nav-item>
            <b-nav-item class="d-flex align-items-center">
              <b-link class="text-white" @click="myBookings">
                <feather-icon icon="BookOpenIcon" size="15" class="mr-50" />
                <span class="align-middle">My Bookings</span>
              </b-link>
           </b-nav-item>
          <!-- <b-nav-item>
              <b-link
                class="text-white"
                :to="{ name: 'User' }"
                v-if="hasPermission('user_show')"
              >
                <feather-icon icon="UserIcon" size="16" class="mr-50" />
                <span>Users</span>
              </b-link>              
            </b-nav-item> -->
          <!-- <b-nav-item>
              <b-link
                class="text-white"
                :to="{ name: 'Role' }"
                v-if="hasPermission('role_show')"
              >
                <feather-icon icon="UsersIcon" size="16" class="mr-50" />
                <span>Roles</span>
              </b-link>        
            </b-nav-item> -->
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <template v-if="isLoggedIn">
            <!-- <router-link to="/booking/bookinghistory" class="text-white">
                <b-button variant="primary" class="rounded-pill">
                  <span>My Booking</span>
                </b-button>
              </router-link> -->

            <b-navbar-nav>
              <b-nav-item-dropdown
                no-caret
                right
                toggle-class="dropdown-user-link"
                class="dropdown-user"
              >
                <template #button-content>
                  <div
                    class="d-flex justify-content-end align-items-center text-right"
                  >
                    <b-avatar
                      badge
                      :src="
                        getLoggedInUser ? getLoggedInUser.profile_image : ''
                      "
                      class="badge-minimal ml-50 mr-50"
                      badge-variant="success"
                    />
                    <div class="user-nav">
                      <p class="user-name text-white font-weight-bolder mb-0">
                        {{ getLoggedInUser ? getLoggedInUser.name : "" }}
                      </p>
                    </div>
                  </div>
                </template>

                <!-- <b-dropdown-item-button
                  button-class="w-100 "
                  @click="myBookings"
                >
                  <feather-icon size="16" icon="UserIcon" class="mr-50" />
                  <span class="">My Bookings</span>
                </b-dropdown-item-button> -->

                <!-- <b-dropdown-divider /> -->

                <b-dropdown-item-button
                  @click="logoutButtonClick"
                  button-class="w-100  "
                >
                  <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
                  <span class="">Logout</span>
                </b-dropdown-item-button>
              </b-nav-item-dropdown>
            </b-navbar-nav>
          </template>
          <template v-else>
            <b-navbar-nav class="ml-5">
              <b-nav-item>
                <b-link
                  class="text-white"
                  :to="{ name: 'Login' }"
                  v-if="!isLoggedIn"
                >
                  <feather-icon icon="UserIcon" size="16" class="mr-50" />
                  <span>Login</span>
                </b-link>
              </b-nav-item>
              <b-nav-item>
                <b-link
                  class="text-white"
                  :to="{ name: 'Register' }"
                  v-if="!isLoggedIn"
                >
                  <feather-icon icon="UsersIcon" size="16" class="mr-50" />
                  <span>Register</span>
                </b-link>
              </b-nav-item>
            </b-navbar-nav>

            <!-- <div class="
                      d-flex
                      justify-content-center
                      align-items-center
                      h-100
                      px-2
                      ml-auto
                  ">
                  <div class="d-flex justify-content-center align-items-center flex-nowrap mr-2">
                      <b-link
                          class="d-flex align-items-center text-white"
                          :to="{ name: 'Login' }"
                          v-if="!isLoggedIn"
                          >
                          <feather-icon icon="UserIcon" size="16" class="mr-50" />
                          <span>Login</span>
                      </b-link>
                  </div>
                  <div class="d-flex justify-content-center align-items-center flex-nowrap mr-5">
                      <b-link
                          class="d-flex align-items-center text-white"
                          :to="{ name: 'Register' }"
                          v-if="!isLoggedIn"
                          >
                          <feather-icon icon="UsersIcon" size="16" class="mr-50" />
                          <span>Register</span>
                      </b-link>
                  </div>
              </div> -->
          </template>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>
  
  <script>
import { mapActions, mapGetters } from "vuex";
import { $themeConfig } from "@themeConfig";
import store from "@/store";

export default {
  data() {
    return {
      appName: "",
      appFullName: "",
      appLogoImage: "",
      isOpen: false,
      isToggled: false,
    };
  },
  created() {
    this.appName = $themeConfig.app.appName;
    this.appFullName = $themeConfig.app.appFullName;
    this.appLogoImage = $themeConfig.app.appLogoImage;
  },
  async mounted() {
    // (this.$refs.mainNavbar)
    // (this.isOpen)
  },
  computed: {
    ...mapGetters({
      hasRole: "appData/hasRole",
      hasPermission: "appData/hasPermission",
      getLoggedInUser: "appData/getUser",
    }),
    isLoggedIn() {
      return store.getters["appData/isLoggedIn"];
    },
  },
  methods: {
    ...mapActions({ logout: "appData/logout" }),
    async logoutButtonClick() {
      try {
        let user = this.getLoggedInUser
        const res = await this.logout();
        if (res.status === 204) {
          //if (this.$route.name != 'Home') {
          // this.$router.push({ name: "BookingLogin" });
          //}
          if(user.role_data.code_name.includes('bk__')){
            this.$router.push({ name: "BookingLogin" })
          }
          else{
            this.$router.push({ name: "Login" });
          }
        }
      } catch (error) {
        error;
      }
    },
    async myBookings() {
      try {
        if (this.$route.name != "BookingHistory") {
          this.$router.push({ name: "BookingHistory" });
        }
      } catch (error) {
        error;
      }
    },
    abc() {
      // (this.$refs.mainNavbar.getAttribute(':visble'))
      // (document.querySelector(this.$refs.mainNavbar._uid))
    },
  },
  watch: {
    // isOpen(newValue, oldValue) {
    //   (this.isOpen)
    //   if (newValue) {
    //   }
    // },
    // isToggled(newValue, oldValue) {
    //   (this.isToggled)
    //   if (newValue) {
    //   }
    // },
  },
};
</script>
  
    <style scoped>
</style>
    
  
<template>
  <b-modal
    id="cancellation-guidelines-modal"
    centered
    size="lg"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    dialog-class="my-dialog-class"
    hide-footer
  >
    <h2 class="w-100 text-center">Cancellation Policy/ Guidelines</h2>

    <div class="my-3">
      <p>
        A- The cancellation of a booking is <strong>ONLY</strong> the
        prerogative of the person who created the booking, hence option for
        cancellation <strong>(Cancellation Button)</strong> is only
        <strong>visible/ available to the creator of the booking</strong>
      </p>
      <p>
        B- The red coloured <strong>“Cancellation Button”</strong> becomes
        available at <strong>“My Bookings”</strong> the moment a Payment Voucher
        is generated
      </p>
      <p>
        C- If Payment Voucher’s validity (30 mins) expires, the booking is
        automatically cancelled and status displayed is
        <strong>“Not Paid - Cancelled”</strong>
      </p>
      <p>
        D- The Cancellation Button (cancellation option) remains available till
        1600 hours on Check-In Date
      </p>
      <p>
        E- The time based Cancellation Charges
        <strong>(deductions)</strong> calculated on the basis of
        <strong>ONE DAY*</strong> only, are as under:-
      </p>
      <div class="ml-2">
        <p>
          1- <strong>5%</strong>: if cancelled 48 hours before 1200 hours on
          Check-In Date
        </p>
        <p>
          2- <strong>25%</strong>: if cancelled between 48 to 24 hours from 1200
          hours on Check-In Date
        </p>
        <p>
          3- <strong>50%</strong>: if cancelled 24 hours before 1200 hours on
          Check-In Date
        </p>
        <p>
          4- <strong>75%</strong>: if cancelled between 1200 hours to 1600 hours
          on Check-In Date
        </p>
        <p>
          5- <strong>100% (No Refund)</strong>: after 1600 hours on Check-In
          Date
        </p>
      </div>
      <hr />
      <p>
        <strong>*Note</strong>: In case of a booking being cancelled is of more
        then one day, the cancellation charges/ deductions will imply on only
        first day and guest will get <strong>Full Refund</strong> of remaining
        days
      </p>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VueSelectPaginated from "@/components/ui/VueSelectPaginated.vue";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import util from "@/util.js";
import moment from "moment";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueSelectPaginated,
  },
  mixins: [util, togglePasswordVisibility],

  data() {
    return {};
  },
  async mounted() {},
};
</script>
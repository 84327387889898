<template>
  <div>
    <div
      class="d-flex justify-content-between px-5 mr-5 w-100 py-2"
      style="background: white !important"
    >
    <div>
      <b-button-group vertical>
         <b-button
          variant="primary"
          size="sm"
          @click="openPaymentModal"
        >
          <h5 class="text-white" style="font-weight: bold; ">
            Payment Guidelines / Procedure
          </h5>
        </b-button>
        <b-button
          variant="info"
          class="mt-1"
          size="sm"
          @click="openCancellationGuideModal"
        >
          <h5 class="text-white" style="font-weight: bold;">
            Cancellation Policy / Guidelines
          </h5>
        </b-button>
         <b-button
          variant="warning"
          class="mt-1"
          size="sm"
          @click="openearlyCheckInModal"
        >
          <h5 class="text-white" style="font-weight: bold;">
            Early Check-Out Policy / Guidelines
          </h5>
        </b-button>
        <b-button
          class="mt-1"
          size="sm"
          @click="openPolicyModal"
        >
          <h5 class="text-white" style="font-weight: bold;">
            Broad Policy Contours
          </h5>
        </b-button>
        <b-button
          variant="outline-danger"
          size="sm"
          class="mt-1 mr-3 d-xs-block d-md-none"
        >
          <h5 class="text-danger" style="font-weight: bold; ">
            Check-In Time <br/> 1600 Hours
          </h5>
        </b-button>
        <b-button
          variant="outline-danger"
          class="mt-1 mr-3 d-xs-block d-md-none"
          size="sm"
        >
          <h5 class="text-danger" style="font-weight: bold;">
            Check-Out Time <br/> 1200 Hours
          </h5>
        </b-button>
      </b-button-group>
    </div>
    <div class="d-none d-md-block">
      <div class="d-flex align-items-center ml-1 mt-1">
        <b-button-group horizontal>
          <b-button
            variant="outline-danger"
            size="sm"
            class="mt-1 mr-3"
          >
            <h5 class="text-danger" style="font-weight: bold; ">
              Check-In Time <br/> 1600 Hours
            </h5>
          </b-button>
          <b-button
            variant="outline-danger"
            class="mt-1 mr-3"
            size="sm"
          >
            <h5 class="text-danger" style="font-weight: bold;">
              Check-Out Time <br/> 1200 Hours
            </h5>
          </b-button>
        </b-button-group>
      </div>
    </div>
      <div class="d-flex align-items-center ml-1">
        <h4 class="mr-1">Filter by:</h4>
        <v-select
          id="statusOptions"
          label="name"
          v-model="selectedStatus"
          name="statusOptions"
          :options="selectOptions"
          placeholder="Status"
          class="mr-5"
        />
      </div>
    </div>
    <div justify="center mt-2">
      <div
        v-for="(booking, index) in filteredBookings"
        :key="index"
        cols="12"
        sm="6"
        md="4"
      >
        <BookingCard
          @refreshBooking="refreshBooking"
          @cancelBooking="cancelBooking"
          :booking="booking"
          :banks="banks"
          :globalPolicies="globalPolicies"
        />
      </div>
    </div>
    <div
      class="d-flex justify-content-center align-items-center flex-nowrap m-2"
    >
      <b-pagination
        size="lg"
        :total-rows="totalItems"
        v-model="pageNumber"
        :per-page="perPage"
      ></b-pagination>
    </div>
    <b-modal
      v-model="paymentModalVisible"
      title="Payment Guidelines/ Procedure"
      hide-footer
      centered
      size="lg"
    >
      <div>
        <p>
          1. Guests are required to visit/ log-in to their respective
          <strong>Mobile Banking App</strong> and go to
          <strong>Payment Option/ Bill Payments -> 1Bill -> Invoice</strong>
        </p>
        <p>
          2. Besides personal banking mobile app, there are many more payment
          options available for payment of generated 1Bill Invoice Voucher, a
          few are: -
        </p>
        <div class="ml-2">
          <p>
            a. <strong>Bank Branches.</strong> Physically visiting any Bank and
            requesting for payment of 1Link (1Bill) invoice voucher
          </p>
          <p>b. <strong>ATM.</strong> Go to Bill Payment -> 1BILL Invoice</p>
          <p>
            c. <strong>easyPaisa.</strong> Go to Bill Payment -> Others -> 1Bill
            Invoice/ Voucher
          </p>
          <p>
            d. <strong>JazzCash.</strong> Go to Other Payments/ Services ->
            Corporate Payments -> 1Bill Invoice and Voucher
          </p>
        </div>
        <p>
          3. While at 1Bill Invoice Payment window, guests are simply required
          to write/enter/paste Payment Voucher ID
        </p>
        <p>
          4. The banking app will automatically fetch the exact amount of guest
          room(s) charges, as were displayed at respective Portal/ Dashboards
          and received via text SMS
        </p>
        <p>
          5. After confirming the amount, click on
          <strong>Pay/ Submit/ Finish</strong> button
        </p>
        <p>
          6. A message confirming the successful transaction will appear at
          Banking App and besides that, at respective user Portal/ Dashboard ->
          My Bookings the status of said booking will change to
          <strong>CONFIRMED and PAID</strong>
        </p>
        <p>
          7. If the status remains
          <strong>PENDING -> UNPAID or CANCELLED -> UNPAID </strong>(after lapse
          of 30 Mins), it means that the said transaction was not successful
        </p>
        <p>
          8. In such an eventuality <strong>(CANCELLED -> UNPAID)</strong> plus
          if the guest’s bank account has been debited, the guest should launch
          complain/ request for reversal of amount with respective bank’s help
          line
        </p>
      </div>
    </b-modal>
    <CancellationGuidelines
      @modalClosed="onModalClosed"
      :showModal="visible"
      :key="`view-${cancellationGuidelineModalCount}`"
    />
    <EarlyCheckInModal
      @modalClosed="onModalClosed"
      :showModal="visible"
      :key="`get-${earlyCheckInModalCount}`"
    />
    <!-- <BroadPolicyModal
      @modalClosed="onModalClosed"
      :showModal="visible"
      :key="`open-${PolicyModalCount}`"
    /> -->
  </div>
</template>


<script>
import BookingCard from "@/components/booking/history/BookingCard.vue";
import { mapActions, mapGetters } from "vuex";
import CancellationGuidelines from "@/components/booking/admin/CancellationGuidelines.vue";
import EarlyCheckInModal from "@/components/booking/admin/EarlyCheckInModal.vue";

export default {
  components: {
    BookingCard,
    CancellationGuidelines,
    EarlyCheckInModal,
  },
  data() {
    return {
      paymentimage: "/assets/booking/paymentguide.png",
      bookings: [],
      filteredBookings: [],
      pageNumber: 1,
      selectedStatus: "All",
      selectOptions: [{ id: 0, name: "All" }],
      paymentModalVisible: false,
      cancellationGuidelineModalCount: 0,
      earlyCheckInModalCount: 0,
      PolicyModalCount: 0,
      totalItems: 0,
      perPage: 0,
      banks: [],
      visible: false,
      globalPolicies: {},
    };
  },
  async mounted() {
    await this.fetchGlobalPoliciesState();
    this.globalPolicies = this.getGlobalPoliciesState
    await this.getBookingsData();
    await this.fetchBanks();
  },
  methods: {
    ...mapActions({
      getBookings: "appData/getBookings",
      updateBooking: "appData/updateBooking",
      getBanksUnpaginated: "appData/getBanksUnpaginated",
      createBankAccount: "appData/createBankAccount",
      CancelBooking: "appData/CancelBooking",
      fetchGlobalPoliciesState: "appData/fetchGlobalPoliciesState",
    }),
    async fetchBanks() {
      try {
        let res = await this.getBanksUnpaginated({});
        this.banks = res.data;
      } catch (error) {
        console.error("Error fetching banks:", error);
      }
    },
    async refreshBooking() {
      await this.getBookingsData();
      setTimeout(() => {}, 1000);
    },
    async getBookingsData() {
      try {
        let res = await this.getBookings({
          customer: this.getLoggedInUser.id,
          pageNumber: this.pageNumber,
        });

        if (res.status == 200) {
          this.bookings = res.data.results;
          this.filteredBookings = res.data.results;
          this.totalItems = res.data.count;
          this.perPage = res.data.per_page;

          const uniqueSelectOptions = new Set(
            this.selectOptions.map((option) => option.id)
          );
          this.bookings.forEach((booking) => {
            const statusValue = booking.status;
            const statusOption = booking.status_name;
            if (!uniqueSelectOptions.has(statusValue)) {
              this.selectOptions.push({
                id: statusValue,
                name: statusOption,
              });
              uniqueSelectOptions.add(statusValue);
            }
          });
        }
      } catch (error) {
        error;
        this.displayError(error);
      }
    },
    async cancelBooking(booking, checkType, accountDetails) {
      try {
        let temp = {};
        temp["booking_data"] = {
          booking_id: booking.id,
          status: 3,
          updated_by: this.getLoggedInUser.id,
        };

        if (booking.status === 2 && booking.payment_status === 2) {
          let refundAmount =
            booking.total_payment - booking.cancellation_charges.amount;
          temp["booking_data"]["payment_status"] = 3;
          temp["booking_data"]["refund_amount"] =
            parseFloat(refundAmount).toFixed(2);
          temp["booking_data"]["cancellation_fee"] = parseFloat(
            booking.cancellation_charges.amount
          ).toFixed(2);
          temp["booking_data"]["cancellation_penalty_percentage"] = booking.cancellation_charges.percentage;

          if (checkType) {
            temp["account_data"] = {
              iban: accountDetails.iban,
              bank: accountDetails.bank,
              account_title: accountDetails.accountTitle,
            };
          }
          // else {
          //   temp["account_data"] = {
          //     iban: accountDetails.iban,
          //     bank: accountDetails.bank,
          //     account_title: accountDetails.accountTitle
          //   };
          // }
        }

        // if(!checkType)
        // {
        //   temp["account_data"] = {
        //     user: this.getLoggedInUser.id,
        //     iban: accountDetails.iban,
        //     created_by: this.getLoggedInUser.id,
        //   }
        // }
        const res = await this.CancelBooking({
          payload: temp,
          pk: booking.id,
        });
        let response_string = "  ";
        let response_msg = response_string;
        if (res.data.response_msg) {
          response_msg = response_msg.concat(res.data.response_msg);
        }

        if (
          res.status === 200 &&
          booking.status == 2 &&
          booking.payment_status == 2
        ) {
          this.$swal({
            title: "Your Refund will be processed in 7 working days.",
            icon: "success",
            footer:
              '<div>In case of any query please contact us at <br/> <div class="text-center"><strong>051-5686029</strong> and <strong>8000-37611</strong></div></div>',
          });

          this.$nextTick(() => {
            this.getBookingsData();
          });
          this.$emit("modalClosed");
        } else if (
          res.status == 200 &&
          booking.status == 1 &&
          booking.payment_status == 1
        ) {
          this.$swal({
            title: "Booking Cancelled Successfully.",
            icon: "success",
          });

          this.$nextTick(() => {
            this.getBookingsData();
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.show = false;
      }
    },
    openPaymentModal() {
      this.paymentModalVisible = true;
    },
    openCancellationGuideModal() {
      this.cancellationGuidelineModalCount += 1;
      this.visible = true;
      this.$nextTick(() => {
        this.$bvModal.show("cancellation-guidelines-modal");
      });
    },
    openearlyCheckInModal() {
      this.earlyCheckInModalCount += 1;
      this.visible = true;
      this.$nextTick(() => {
        this.$bvModal.show("early-checkin-modal");
      });
    },
    openPolicyModal() {
      this.PolicyModalCount += 1;
      this.visible = true;
      this.$nextTick(() => {
        this.$bvModal.show("broad-policy-modal");
      });
    },
    closeModal() {
      this.showModal = false;
    },
    onModalClosed() {
      this.visible = false;
      this.$bvModal.hide("cancellation-guidelines-modal");
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
      getLoggedInUser: "appData/getUser",
      getGlobalPoliciesState: "appData/getGlobalPoliciesState",
    }),
  },
  watch: {
    selectedStatus(newValue) {
      const query = newValue.id;
      if (!query || query === 0) {
        this.filteredBookings = this.bookings;
      } else {
        this.filteredBookings = this.bookings.filter(
          (booking) => booking.status === query
        );
      }
    },
    pageNumber: async function (val) {
      await this.getBookingsData();
    },
  },
};
</script>

<style>
.payment-guide {
  background: url("~@/assets/booking/paymentguide.png") no-repeat center/cover;
  background-size: cover;
  color: #fff;
  border-radius: 10px;
  overflow: hidden;
  height: 500px;
  width: 770px;
}
</style>

<template>
  <b-modal
    id="broad-policy-modal"
    centered
    size="lg"
    dialog-class="my-dialog-class"
    hide-footer
  >
    <h1 class="w-100 text-center">Broad Policy Contours</h1>
    <h2 class="w-100 text-center">Guest Room Booking Portal</h2>

    <div class="my-3">
      <h3>
       1.	<strong>A booking once created</strong> (paid/ confirmed), <strong>can only be CANCELLED by the guest/ user</strong> who created that booking
      </h3>
      <br>
      <h3>
        2.	As per policy in vogue, <strong>serving officers only are enabled to refer/ book guest rooms for civil guests.</strong> It is sole responsibility of officer concerned to ensure that the guests (civil) for whom booking is being created, are close relative/ very well known
      </h3>
      <br>
      <h3>
        3.	<strong>Parents and Children</strong> (un-married/ under the age of 24 years) of serving officers are levied guest rooms charges as of “Serving/ Self” category. Whereas, for retired officers, Children (un-married/ under the age of 24 years) only are allowed/ levied guest rooms charges as of “Retired/ Self” category
      </h3>
      <br>
      <h3>
       4.	<strong>Identification by Mess Staff is mandatory</strong> i.e. PAID Card for Officer, his/her Parents/ Children and CNIC (Original) for civil guests
      </h3>
      <br>
      <h3>
       5.	<strong>Users are required to remit 100% advance</strong> through software generated Payment Voucher ID, online/ multiple options i.e. <strong>1Link/ 1Bill Invoice.</strong> <i style="background-color: yellow;">Please visit “Payment Guidelines” given at home page of dashboard</i>
      </h3>
      <br>
      <h3>
        6. Refunds on <strong>Cancellation</strong> and <strong>Early Check-Out</strong> are made after certain deductions based on remaining time from Check-In Time/ Date. Guests are advised to <h3 style="background-color: yellow">visit and read carefully the Policy/ Guidelines as given at home page of dashboard i.e. <strong>Cancellation Policy/ Guidelines</strong> and <strong>Early Check-Out Policy/ Guidelines</strong></h3>
      </h3>
      <br>
        <h3>
        7.	<strong>Cancellation charges are NOT levied on On Duty bookings,</strong> provided the officer concerned initially did follow On Duty booking stream while creating the said booking. To avail full refund wrt On Duty Cancellation, the offr concerned must process the case on OAS with AGM Cell, SD Dte providing all necessary details/ documentss wrt duty/ cancellation of duty
        </h3>
    </div>
    <div class="d-flex justify-content-center">
    <b-button
      variant="primary"
      @click="closeModal">
      OK
    </b-button>
    </div>


  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VueSelectPaginated from "@/components/ui/VueSelectPaginated.vue";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import util from "@/util.js";
import moment from "moment";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueSelectPaginated,
  },
  mixins: [util, togglePasswordVisibility],

  data() {
    return {};
  },

  async mounted() {},
   methods: {
      ...mapActions({
      }),
    closeModal()
    {
        this.$bvModal.hide("broad-policy-modal");
    }
  },
};
</script>
<template>
  <div class="wrapper">
    <div class="container">
      <div class="colmun colmun-left center-content">
        <img :src="require('@/assets/images/logo/cne.svg')" alt="image-left" style="height: 150px; width:150px;">
        <h1 class="px-spc-b-20 mt-5">Apologies </h1>
        <h2 class="px-spc-b-20"
          >{{ error }}</h2
        >

        <button class="go-home btn-lg btn-primary" @click="redirectToHome">
          <i class="fa fa-home"></i> Go Home
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    error: String, 
  },
  methods: {
    redirectToHome() {
      this.$router.push("/");
    },
  },
};
</script>


<style scoped>
.center-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh; /* Optional: Center vertically in viewport */
}
h1 {
  font-size: 50px;
  line-height: 60px;
}
span {
  display: block;
  font-size: 18px;
  line-height: 30px;
}
.container {
  width: 80%;
  max-width: 1600px;
  margin: auto;
}
.grid-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  grid-gap: 50px;
}
.colmun-left {
  text-align: left;
}
.colmun-right {
  text-align: right;
}
.px-spc-b-20 {
  padding-bottom: 20px;
}
/* button.go-home {
  padding: 5px 20px;
  background: #ffa000;
  border: transparent;
  border-radius: 2px;
  box-shadow: 0 0 2px rgb(0 0 0 / 30%);
  cursor: pointer;
  margin: 20px 0;
  color: #fff;
}
button.go-home i {
  color: #fff;
} */
img {
  display: block;
  width: 100%;
}
</style>
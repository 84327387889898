
 <template>
  <div class="BookingHistory-page bg-white">
    <b-row class="pt-2 pb-2 px-3">
      <b-col md="3"></b-col>
      <b-col md="6" class="card mb-0 ">
        <div class="d-flex justify-content-between align-items-center">
          <h4>
            <span class="badge mt-1 mr-50" style="background-color: #355e3b">
              {{ booking.mess_data.name }}
            </span>
          </h4>
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="mr-1">
              <span
                :style="{ backgroundColor: getStatusColor(booking.status) }"
                class="badge mt-1"
              >
                {{ booking.status_name }}
              </span>
            </h4>
            <h4>
              <span
                :style="{
                  backgroundColor: getPaymentStatusColor(
                    booking.payment_status
                  ),
                }"
                class="badge mt-1"
              >
                {{ booking.payment_status_name }}
              </span>
            </h4>
          </div>
        </div>
        <div class="card-text">
          <div class="row">
             <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12">
              <h6><strong>Booking Id</strong></h6>
              <p>{{ booking.id }}</p>
              <h6><strong>Rooms</strong></h6>
              <p>{{ booking.rooms.length }}</p>
              <h6><strong>City</strong></h6>
              <p>{{ booking.mess_data.city_name }}</p>
              <h6><strong>Voucher No.</strong></h6>
              <div class="d-flex align-items-center flex-nowrap  ">
                <p class="mr-50">786786{{ booking.voucher_no.split(" ")[0] }}</p>
                <feather-icon icon="CopyIcon" class="copy-icon mb-1" style="width: 20px; height: 20px;" @click="copyToClipboard"/>
              </div>
            </div>

             <div class="col-md-6 col-lg-6 col-xl-6 col-sm-12">
              <h6><strong>Check-in Date</strong></h6>
              <p>{{ booking.start_date_time.split(" ")[0] }}</p>
              <h6><strong>Check-out Date</strong></h6>
              <p>{{ booking.end_date_time.split(" ")[0] }}</p>
              <h6><strong>Amount</strong></h6>
              <p>PKR {{ booking.total_payment }}</p>
              <template v-if="(booking.status == 1)  && ( minutesLeft && (minutesLeft !== '00')) && ( secondsLeft && (secondsLeft !== '00'))">
                <h6><strong>Time Left</strong></h6>
                <p>{{ minutesLeft }} : {{ secondsLeft }}</p>
              </template>           
            </div>
          </div>
        </div>
        <div
        >
          <b-button
            @click="checkout"
            :disabled="true"
            variant="warning"
            class="mb-2 mr-2"
          >
            Checkout
          </b-button>
          <b-button
            @click="earlyCheckout"
            :disabled="true"
            variant="success"
            class="mb-2"
          >
            Early Checkout
          </b-button>
           <b-button v-if="booking.checked_out && !booking.feedback_submitted"
            variant="info"
            @click="feedbackPage(booking)"
            class="mb-2 ml-2">
            Submit feedback
          </b-button>
        </div>
        <div class="text-right mb-1" v-if="(booking.status !== 3) && (!booking.checked_in) && (  (booking.cancellation_charges.hours_left > 0)  || (booking.cancellation_charges.minutes_left > 0) || (booking.cancellation_charges.seconds_left > 0) )">
          <!-- <b-button @click="makePayment()" variant="primary" class="p-1 mr-2">Make Payment</b-button> -->
          <b-button variant="danger" @click="ConfirmCancellation"
            >Cancel Booking</b-button
          >

          <b-modal
            id="cancellation"
            v-model="showCancellationModal"
            title="Cancellation Charges"
            @hide="resetCancellationModal"
            hide-footer
          >
            <div>
              <h1 class="text-center">Summary</h1>
              <hr />
              <div>
                <h4>
                  Cancellation Fee : {{ booking.cancellation_charges.amount }}
                </h4>

                <h4>
                  Hours left until 1600 hours check-in date:
                  {{
                    booking.cancellation_charges.hours_left > 0
                      ? booking.cancellation_charges.hours_left
                      : 0
                  }}
                </h4>
                <h4>
                  Hours left until 1200 hours check-in date:
                  {{
                    booking.cancellation_charges.hours_left_1200
                  }}
                </h4>
                <h4>
                  Percentage Deduction :
                  {{ booking.cancellation_charges.percentage }}
                </h4>
                <hr />
                <div>
                <h5 class="text-danger">
                  Ensure Accurate/ correct Bank Details. Revisit / Check / Edit Bank details if not refunded within seven working days
                </h5>
              </div>
              <hr />
              <div class="text-danger border-danger rounded p-75">
                <p>1. Account Title should be in CAPITAL ALPHABETS</p>
                <p>2. IBAN should be in CAPITAL ALPHABETS, start with PK (in CAPITAL ALPHABETS) and should be 24 digits long</p>
              </div>
              <hr />
                <validation-observer ref="paymentMethodFormValidation">
                  <b-form @submit.prevent>
                      <b-form-group
                        label-for="accountTitle"
                        :state="accountTitle ? true : false"
                      >
                        <h4>Enter your Account Title [Account Holder Name]</h4>
                        <b-row>
                          <b-col>
                            <validation-provider #default="{ errors }" name="accountTitle" :rules="{ required: true, regex: /^[A-Z0-9@,. ''/_-]*$/}">
                              <b-form-input
                                id="accountTitle"
                                v-model="accountTitle"
                                placeholder="Enter Account Title"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <b-form-invalid-feedback>
                          Please enter your Account title
                        </b-form-invalid-feedback>
                      </b-form-group>
                      <b-form-group
                        label-for="bank"
                        :state="selectedBank ? true : false"
                      >
                        <h4>Select your Bank Name</h4>
                        <b-row>
                          <b-col>
                            <validation-provider #default="{ errors }" name="bank" :rules="{ required: true }">
                              <v-select
                                id="bank"
                                label="name"
                                v-model="selectedBank"
                                :options="banks"
                                placeholder="Select Bank"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <b-form-invalid-feedback>
                          Please select a bank.
                        </b-form-invalid-feedback>
                      </b-form-group>
                    <b-form-group label-for="checkType">
                    <!-- <template #label>
                      Choose <span class="text-danger">*</span>
                    </template> -->
                    <!-- <b-form-radio-group id="checkType" v-model="checkType">
                      <b-form-radio v-model="checkType" :value="true">
                        Account Number
                      </b-form-radio>
                      <b-form-radio v-model="checkType" :value="false">
                        IBAN
                      </b-form-radio>
                    </b-form-radio-group> -->
                  </b-form-group>
                    <!-- <div v-if="checkType">
                      <b-form-group
                        label-for="accountNumber"
                        :state="accountNumber ? true : false"
                      >
                        <h4>Enter your Account Number</h4>
                        <b-row>
                          <b-col>
                            <validation-provider #default="{ errors }" name="accountNumber" :rules="{ required: checkType, regex: /^[0-9]*$/ }">
                              <b-form-input
                                id="accountNumber"
                                v-model="accountNumber"
                                placeholder="Enter Account Number"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <b-form-invalid-feedback>
                          Please enter your account number.
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div> -->
                    <div v-if="checkType">
                      <b-form-group
                        label-for="ibanNumber"
                        :state="ibanNumber ? true : false"
                      >
                        <h4>Enter your IBAN Number</h4>
                        <b-row>
                          <b-col>
                           <validation-provider #default="{ errors }" name="ibanNumber" :rules="{ required: !checkType, regex: /^PK[A-Z0-9]{22}$/}">
                            <b-form-input
                              id="ibanNumber"
                              v-model="ibanNumber"
                              placeholder="Enter IBAN Number"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                          </b-col>
                        </b-row>
                        <b-form-invalid-feedback>
                          Please enter your IBAN number.
                        </b-form-invalid-feedback>
                      </b-form-group>                  
                    </div>
                  </b-form>
                </validation-observer>
              </div>

              <hr />
              <div>
                <b-table
                  :items="staticCancellationCharges"
                  :fields="fields"
                  class="w-100"
                  style="white-space: nowrap;"
                  td-class="text-wrap"
                >
                <template #cell(type)="row">
                  <span class="text-wrap">{{ row.value }}</span>
                </template>
                </b-table>
              </div>
              <div>
                <h5>
                  Note: Deductions will be calculated on basis of Guest Room
                  Charges of ONE Day only.
                </h5>
              </div>

              <hr />
              <div
                class="d-flex justify-content-center align-items-center flex-nowrap mb-1"
              >
                <div
                  class="d-flex justify-content-center align-items-center flex-nowrap mr-2"
                >
                  <b-button @click="back" variant="secondary" class="pl-2 pr-2"
                    >Back</b-button
                  >
                </div>

                <div
                  class="d-flex justify-content-center align-items-center flex-nowrap mr-2"
                >
                  <b-button
                    @click="cancelPaidBooking(booking)"
                    :disabled ="CancelButtonDisabled"
                    variant="danger"
                    class="pl-2 pr-2"
                    >Cancel Booking</b-button
                  >
                </div>
              </div>

              <!--  -->
            </div>
          </b-modal>
        </div>
      </b-col>
      <b-col md="2"></b-col>
    </b-row>
  <div>
    <feedbackSubmitModal
      v-if="showFeedbackModal"
      @onModalClosed="closeFeedbackModal"
      :booking ="booking"
      :key="`policy-${feedbackModalCount}`"
    />
  </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import BillPayment from '@/components/booking/admin/BillPayment.vue';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, regex, max } from "@validations";
import feedbackSubmitModal from "@/components/booking/admin/feedbackSubmitModal.vue";

export default {
  components: {
    //  BillPayment,
    ValidationProvider,
    ValidationObserver,
    feedbackSubmitModal
  },
  data() {
    return {
      feedbackModalCount: 0,
      checkType: true,
      selectedBank: null,
      accountTitle: "",
      accountNumber: "",
      ibanNumber: "",
      bookings: [],
      pageNumber: 1,
      Amount: "",
      Time: "",
      Percentage: "",
      cancellation_charges: [],
      showCancellationModal: false,
      fields: [
        { key: "type", label: "Type", class: "text-nowrap" },
        { key: "charge", label: "Charge" },
      ],
      BookingData: [
        { key: "type", label: "Type", class: "text-nowrap" },
        { key: "charge", label: "Charge" },
      ],
      paymentModalCount: 0,
      staticCancellationCharges: [
        { type: "48 hours before 1200 hours on Check-In Date", charge: "5%" },
        { type: "Between 48 to 24 hours before 1200 hours on Check-In Date", charge: "25%" },
        { type: "Last 24 hours before 1200 hours on Check-In Date (excluding 1200 to 1600 hours)", charge: "50%" },
        { type: "Between 1200 hours to 1600 hours on Check-In Date", charge: "75%" },
        { type: "After 1600 hours i.e. Check-In time", charge: "100%" },
      ],
    
      selectedBank: null,
      serverTime: null,
      currentTime: null,
      updateInterval: null,
      bookingCreationTime: null,
      bookingExpiryTime: null,
      minutesLeft: '',
      secondsLeft: '',
      showFeedbackModal: false,
    };
  },

  props: {
    booking: Object,
    banks: Array,
    globalPolicies: Object
  },
  async mounted() {
    if(this.globalPolicies){
      if(this.booking.created_at){
          await this.fetchSystemTime()
          var systemDateTime  = await this.getSystemTime
          this.serverTime = new Date(systemDateTime);
          // this.bookingCreationTime = new Date(this.booking.created_at + ' +05:00');
          let dateStringISO8601 = this.booking.created_at + ' +05:00'
          let parts = dateStringISO8601.split(' ');
          let datePart = parts[0]; // '2024-07-05'
          let timePart = parts[1]; // '00:00:00'
          let offsetPart = parts[2]; // '+05:00'
          let formattedDateString = datePart + 'T' + timePart + offsetPart;
          this.bookingCreationTime = new Date(formattedDateString);
          this.bookingExpiryTime = new Date(this.bookingCreationTime.getTime() + (parseInt(this.globalPolicies.booking_validity) * 60000));
          this.currentTime = new Date(systemDateTime)
          if(this.booking.status == 1)
          {
            this.updateInterval = setInterval(() => {
              this.remainingTime = Math.floor((this.bookingExpiryTime.getTime() - this.currentTime.getTime()) / 1000);
              const minutes = Math.floor(this.remainingTime / 60);
              this.minutesLeft = minutes >= 0 ? String(minutes).padStart(2, '0') : '00'
              const seconds = this.remainingTime % 60;
              this.secondsLeft = seconds >= 0 ? String(seconds).padStart(2, '0') : '00';
              this.currentTime = new Date(this.currentTime.getTime() + 1000)
            }, 1000);
          }
        }
    }
  },
  methods: {
    ...mapActions({
      getBooking: "appData/getBooking",
      fetchSystemTime: "appData/fetchSystemTime",
    }),
    earlyCheckout()
    {

    },
    checkout()
    {

    },
    feedbackPage(booking)
    { 
      this.booking =  booking;
      this.showFeedbackModal = true
      this.$nextTick(()=>{
        this.$bvModal.show("booking-feedback-modal")
      })
    },
    closeFeedbackModal() {
      this.showFeedbackModal = false;
    },
    getStatusColor(status) {
      switch (status) {
        case 2:
          return "#40a829";
        case 1:
          return "#FEBE10";
        case 3:
          return "#FF4500";
        default:
          return "#fd5c63";
      }
    },
    getPaymentStatusColor(status) {
      switch (status) {
        case 1:
          return "#FEBE10";
        case 2:
          return "#40a829";
        default:
          return "#fd5c63";
      }
    },
    async cancelBooking(booking) {
        try {
          this.$swal({
            title: "Are you sure? This booking will be cancelled.",
            icon: "warning",
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: "Confirm",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-danger ml-1",
            },
            buttonsStyling: false,
          }).then(async (result) => {
            if (result.value) {
              // this.$emit("cancelBooking");
              this.$emit("cancelBooking", booking, this.checkType, null);
            } else {
              this.show = false;
            }
          });
        } catch (error) {
          this.displayError(error);
        }
    },

    async cancelPaidBooking(booking) {
      const success = await this.$refs.paymentMethodFormValidation.validate();
      if (success) { 
        try {
            // this.$emit("cancelBooking");
            let accountDetails = {
              accountTitle: this.accountTitle,
              accountNumber: this.accountNumber,
              iban: this.ibanNumber,
              bank: this.selectedBank ? this.selectedBank.id : null,
            }
            this.$emit("cancelBooking", booking, this.checkType, accountDetails);
        } catch (error) {
          this.displayError(error);
        }
      }
    },    
    cancel() {
      this.showCancellationModal = false;
    },
    back() {
      this.showCancellationModal = false;
    },
    // makePayment(){
    //    this.paymentModalCount += 1;
    //   this.$nextTick(() => {
    //     this.$bvModal.show("payment-modal");
    //   });
    // },
    async ConfirmCancellation(booking) {
      this.$emit("refreshBooking");
      let res = await this.getBooking(this.booking.id)
      let updatedBooking = res.data
      
      if (updatedBooking.status === 1 && updatedBooking.payment_status === 1) {
        this.cancelBooking(updatedBooking);
      }
       else if ( updatedBooking.status === 2 && updatedBooking.payment_status === 2) {
        this.showCancellationModal = true;
      } 
      else {
        this.$swal({
          title: "Booking cannot be cancelled.",
          text: "This booking is not eligible for cancellation.",
          icon: "info",
          button: "Okay",
        });
      }
    },
    copyToClipboard() {
      const el = document.createElement('textarea');
      el.value = "786786" + this.booking.voucher_no.split(" ")[0];
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    },
    resetCancellationModal() {
      this.showCancellationModal = false;
    },
    formatNumber(number) {
      return number < 10 ? `0${number}` : number;
    },
    onModalClosed() {
      this.booking = null;
      this.$bvModal.hide("booking-feedback-modal");
    },
  },
  computed: {
    ...mapGetters({
      getSystemTime: "appData/getSystemTime",
    }),
    CancelButtonDisabled() {
      return !this.accountTitle || !this.selectedBank || 
            // (this.checkType && !this.accountNumber) || 
            (this.checkType && !this.ibanNumber);
    },
  },
  beforeDestroy() {
    if(this.updateInterval){
      clearInterval(this.updateInterval);
    }
  }
};
</script>
<style scoped>
.copy-icon{
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}
.copy-icon:hover{
   transform: scale(1.2);
}
.text-wrap {
  white-space: normal !important;
}

</style>
<template>
  <div style="height: 100vh">
    <!-- <Topbar /> -->
    <Navbar />
    <!-- <Filter /> -->
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import Topbar from "@/components/home/Topbar.vue";
import Navbar from "@/components/booking/home/Navbar.vue";
// import Filter from "@/components/home/Filter.vue";
import Body from "@/components/booking/home/Body.vue";
import Footer from "@/components/booking/home/Footer.vue";
import util from "@/util.js";


export default {
  components: {
    // Topbar,
    Navbar,
    // Filter,
    Body,
    Footer,
  },
  mixins: [util],
  data() {
    return {
      data: {},
    };
  },
  async mounted() {},
  methods: {
    // ...mapActions({
    //   getDashboardData: "appData/getDashboardData",
    //   getDashboardLineChart: "appData/getDashboardLineChart",
    // }),
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
      getLoggedInUser: "appData/getUser",
    }),
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<template>
    <b-modal id="booking-feedback-modal" title="Feedback Submit Modal" hide-footer scrollable size="xl"
     :no-close-on-esc="true" :no-close-on-backdrop="true" >
 <div class="feedback-container">
        <hr style="border-color: #000; width: 60%" />
        <p>{{ selectedRoom }}</p>
        <p style="background: rgb(52, 83, 52); color: white;" class="p-1">GUESTROOM BOOKING FEEDBACK FORM</p>
        <hr style="border-color: #000000; width: 60%" />
      </div>
  <div class="text-black">
      <!-- Form  -->
      <div class="mt-2 m-1">
        <validation-observer ref="feedbackFormValidation">
          <b-form @submit.prevent>
            <b-row>
            <b-col></b-col>
              <b-col md="6">
                <b-form-group>
                  <label for="voucher" style="width: 50%">
                    <div class="d-flex justify-content-between">
                      <div class="d-inline-block">
                        <span style="font-size: 14px">Voucher Number </span>
                          <span class="text-danger"> *</span>
                      </div>
                      <span
                        class="ml-2 d-inline-block"
                        style="
                          font-family: 'Noto Nastaliq Urdu', serif;
                          text-align: right !important;
                          font-size: 14px;
                        "
                        >بکنگ واؤچر نمبر
                      </span>
                    </div>
                  </label>
                  <validation-provider
                    #default="{ errors }"
                    name="Voucher"
                    rules="required|integer|length:18"
                  >
                    <div class="input-group">
                      <input type="text" class="form-control1 p-50 rounded" value="786786" disabled style="width: 90px !important; border: 1px solid #d8d6de; ">
                      <input type="text" class="form-control " v-model="voucher" name="voucher" placeholder="0129XXXXXXXXX" disabled>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3"></b-col>
            </b-row>
            <b-row>
              <b-col md="3"></b-col>
              <b-col md="6">
                <b-form-group>
                  <label for="cnic" style="width: 100%">
                    <div class="d-flex justify-content-between">
                      <div class="d-inline-block">
                        <span style="font-size: 14px">Cnic</span>
                          <span class="text-danger"> *</span>
                      </div>
                      <span
                        class="ml-2 d-inline-block"
                        style="
                          font-family: 'Noto Nastaliq Urdu', serif;
                          text-align: right !important;
                          font-size: 14px;
                        "
                        >شناختی کارڈ
                      </span>
                    </div>
                  </label>
                  <validation-provider
                  #default="{ errors }"
                  name="CNIC"
                  rules="required|integer|length:13"
                >
                  <b-form-input
                    id="cnic"
                    v-model="cnic"
                    :state="errors.length > 0 ? false : null"
                    name="cnic"
                    placeholder="61101XXXXXXXX"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3"></b-col>
            </b-row>
            <b-row>
              <b-col md="3"></b-col>
              <b-col md="6">
                <b-form-group>
                  <label for="name" style="width: 100%">
                    <div class="d-flex justify-content-between">
                      <div class="d-inline-block">
                        <span style="font-size: 14px">Name</span>
                          <span class="text-danger"> *</span>
                      </div>
                      <span
                        class="ml-2 d-inline-block"
                        style="
                          font-family: 'Noto Nastaliq Urdu', serif;
                          text-align: right !important;
                          font-size: 14px;
                        "
                        >نام
                      </span>
                    </div>
                  </label>
                  <validation-provider
                  #default="{ errors }"
                  name="name"
                  :rules="{ required, regex: /^[A-Za-z\s]{1,50}$/ }"
                  >
                  <b-form-input
                    id="name"
                    v-model="name"
                    :state="errors.length > 0 ? false : null"
                    name="name"
                    maxlength="30"
                    placeholder="Name"
                    disabled
                  />
                  <small v-if="errors.length" class="text-danger">{{
                    errors[0]
                  }}</small>
                </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3"></b-col>
            </b-row>
            <b-row>
              <b-col md="3"></b-col>
              <b-col md="6">
                <!-- Mobile -->
                <b-form-group>
                  <label for="mobile" style="width: 100%">
                    <div class="d-flex justify-content-between">
                      <div class="d-inline-block">
                        <span style="font-size: 14px">Mobile </span
                        ><span class="text-danger"> *</span>
                      </div>
                      <span
                        class="ml-2 d-inline-block"
                        style="
                          font-family: 'Noto Nastaliq Urdu', serif;
                          text-align: right !important;
                          font-size: 14px;
                        "
                        >موبائل</span
                      >
                    </div>
                  </label>

                  <validation-provider
                  #default="{ errors }"
                  name="Mobile"
                  rules="required|integer|length:11"
                >
                  <b-form-input
                    id="mobile"
                    v-model="mobile"
                    :state="errors.length > 0 ? false : null"
                    name="mobile"
                    placeholder="0310XXXXXXX"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3"></b-col>
            </b-row>
             <b-row>
              <b-col md="3"></b-col>
              <b-col md="6">
                <b-form-group>
                  <label for="name" style="width: 100%">
                    <div class="d-flex justify-content-between">
                      <div class="d-inline-block">
                        <span style="font-size: 14px">Rooms</span>
                          <span class="text-danger"> *</span>
                      </div>
                      <span
                        class="ml-2 d-inline-block"
                        style="
                          font-family: 'Noto Nastaliq Urdu', serif;
                          text-align: right !important;
                          font-size: 14px;
                        "
                        >کمرے
                      </span>
                    </div>
                  </label>
                  <validation-provider
                  #default="{ errors }"
                  name="rooms"
                  :rules="{ required, regex: /^[A-Za-z0-9(),#&*_-\s]{1,100}$/ }"
                  >
                  <b-form-input
                    id="rooms"
                    v-model="rooms"
                    :state="errors.length > 0 ? false : null"
                    name="rooms"
                    maxlength="30"
                    placeholder="Please enter your room no / name"
                  />
                  <small v-if="errors.length" class="text-danger">{{
                    errors[0]
                  }}</small>
                </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3"></b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
      <!-- Rating -->
      <div class="mt-2 m-1">
        <!-- Cleanliness -->
        <div class="feedback-content mb-50">
          <b-row>
            <b-col md="3"></b-col>
            <b-col md="4">
              <p>1. How did our mess staff behave during your stay?</p>
            </b-col>
            <b-col md="3">
              <div class="radio-buttons">
                <star-rating
                  :star-size="25"
                  :max-rating=10
                  v-model="staffRating"
                  variant="warning"
                  class="align-stars"
                ></star-rating>
              </div>
            </b-col>
          </b-row>
        </div>
        <!-- Comfort -->
        <div class="feedback-content mb-50">
          <b-row>
            <b-col md="3"></b-col>
            <b-col md="4">
              <p>2. How did you find the overall cleanliness of our Mess/ Guestroom?</p>
            </b-col>
            <b-col md="3">
              <div class="radio-buttons">
                <star-rating
                  :star-size="25"
                  :max-rating=10
                  v-model="cleanlinessRating"
                  class="align-stars"
                ></star-rating>
              </div>
            </b-col>
          </b-row>
        </div>
        <!-- Location -->
        <div class="feedback-content mb-50">
          <b-row>
            <b-col md="3"></b-col>
            <b-col md="4">
              <p>3. How did you find the cleanliness of your room?</p>
            </b-col>
            <b-col md="3">
              <div class="radio-buttons">
                <star-rating
                  :star-size="25"
                  :max-rating=10
                  v-model="roomRating"
                  class="align-stars"
                ></star-rating>
              </div>
            </b-col>
          </b-row>
        </div>
        <!-- Facilities -->
        <div class="feedback-content mb-50">
          <b-row>
            <b-col md="3"></b-col>
            <b-col md="4">
              <p>4. How was the quality of food?</p>
            </b-col>
            <b-col md="3">
              <div class="radio-buttons">
                <star-rating
                  :star-size="25"
                  :max-rating=10
                  v-model="foodRating"
                  class="align-stars"
                ></star-rating>
              </div>
            </b-col>
    
          </b-row>
        </div>
        <!-- Staff -->
        <div class="feedback-content mb-50">
          <b-row>
            <b-col md="3"></b-col>
            <b-col md="4">
              <p>5. How would you rate our Online Booking Appl?</p>
            </b-col>
            <b-col md="3">
              <div class="radio-buttons">
                <star-rating
                  :star-size="25"
                  :max-rating=10
                  v-model="appRating"
                  class="align-stars"
                ></star-rating>
              </div>
            </b-col>
          </b-row>
        </div>

        <!-- Value For Money-->
        <div class="feedback-content mb-50">
          <b-row>
            <b-col md="3"></b-col>
            <b-col md="4">
              <p>6. How would you rate our Mess, overall?</p>
            </b-col>
            <b-col md="3">
              <div class="radio-buttons">
                <star-rating
                  :star-size="25"
                  :max-rating=10
                  v-model="overallRating"
                  class="align-stars"
                ></star-rating>
              </div>
            </b-col>
          </b-row>
        </div>
        <!-- Any other Observation / Suggestion -->
        <div class="feedback-content mb-2">
          <b-row>
            <b-col md="3"></b-col>
            <b-col md="4">
              <p class="mb-1">7. Any other observation(s) / suggestion(s):</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3"></b-col>
            <b-col md="6">
              <div>
                <b-form-textarea
                    id="textarea-rows"
                    placeholder="Any suggestions / observation"
                    rows="8"
                    v-model="suggestion"
                    :maxlength="1000"
                ></b-form-textarea>
                </div>
            </b-col>
            <b-col md="3"></b-col>
          </b-row>
        </div>

        <!-- Submit Button -->
        <b-form-group class="text-center">
          <b-button
            class="mb-5"
            type="submit"
            variant="primary"
            pill
            @click="validationForm"
          >
            Submit Feedback
          </b-button>
        </b-form-group>
      </div>
      <b-overlay :show="show" spinner-variant="primary" no-wrap />
  </div>
</b-modal>    
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ErrorPage from "@/components/ErrorPage.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import StarRating from "vue-star-rating";
import { heightTransition } from "@core/mixins/ui/transition";
export default {
  components: {
    StarRating,
    ValidationProvider,
    ValidationObserver,
    ErrorPage,
  },
  props: {
    booking: Object  
  },
  mixins: [util, heightTransition],
  data() {
    return {
      show: false,
      updated: false,
      selectedRoom:"",
      cleanlinessRating:1,
      roomRating:1,
      appRating:1,
      foodRating:1,
      overallRating:1,
      staffRating:1,
      suggestion:'',
      voucher: '',
      cnic: '',
      name: '',
      mobile: '',
      roomId: null,
      rooms: '',
      required,
    };
  },
  mounted(){
    
    this.voucher = this.booking.voucher_no;
    this.name = this.booking.customer.name;
    this.cnic = this.booking.customer.cnic;
    this.mobile = this.booking.customer.mobile;
     if (this.booking.rooms.length > 0) {
      this.roomId = this.booking.rooms[0].id;
    } else {
      return
    }

    // console.log('Room ID:', this.roomId);
  },
  methods: {
    ...mapActions({
      customerBookingFeedback: "appData/customerBookingFeedback",
      getFeedbackData: "appData/getFeedbackData",
    }),
    async validationForm() {
      try {
        const success = await this.$refs.feedbackFormValidation.validate();
        if (success) {
          await this.submit();
        }
      } catch (error) {
        error;
        this.displayError(error);
      }
    },
    async submit() {
      this.show = true;
      try {
        const res = await this.customerBookingFeedback({
          voucher_no: this.voucher,
          cnic: this.cnic,
          name: this.name,
          mobile: this.mobile,
          room: this.roomId,
          rooms_booked : this.rooms,
          staff_behaviour: this.staffRating,
          mess_cleanliness: this.cleanlinessRating,
          overall_mess_rating: this.overallRating,
          room_cleanliness: this.roomRating,
          food_quality: this.foodRating,
          application_rating: this.appRating,
          other_observation_suggestion: this.suggestion,
        });

        if (res.status === 201) {
          await this.displaySuccess(
            "Feedback Submitted!"
          );

          this.$router.push({ name: "BookingHome" });

          this.show = false;
          this.reset();
          this.$refs.feedbackFormValidation.reset();
          this.$refs.VueCanvasDrawing.reset();
        }
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },
    reset() {
      this.voucher = '',
      this.cnic = '',
      this.name = '',
      this.mobile = '',
      this.roomId = null,
      this.staffRating = 1;
      this.cleanlinessRating = 1;
      this.overallRating = 1;
      this.roomRating = 1;
      this.foodRating = 1;
      this.appRating = 1;
      this.suggestion = '';
    },
  },
};
</script>


<style scoped>
.feedback-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.radio-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.align-stars {
  justify-content: between;
}

p {
  font-weight: 700;
}
.urdu {
  font-family: "Noto Nastaliq Urdu", serif !important;
  font-size: 14px !important;
  font-weight: 600;
  display: flex;
  justify-content: end;
  margin-top: -8px;
}

.feedback-content {
  margin-top: 20px;
  padding-bottom: 25px;
}

.text-black {
  color: black !important;
}
::placeholder {
  color: black;
}
label {
  font-size: 110%;
  font-weight: 400;
  color: #000;
}
</style>
